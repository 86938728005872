<template>
  <div>
    <div class="row">
      <div v-if="showTitle" class="col-md-6">
        <h3>{{ translate(getTitle()) }}</h3>
      </div>
    </div>

    <div class="group">
      <div :class="singleColumn ? 'single-column' : 'left'">
        <el-form-item
          v-for="(item, itemIdx) in getColumnItems(0)"
          :label="getLabelText(item.meta)"
          :title="getLabelTitle(item.meta)"
          :prop="getValidationPropName(item.meta, entityIdx)"
          :key="itemIdx"
          :class="getLabelClass(item.meta)"
        >
          <cc-var-control
            :metadata="item.meta"
            :entity="item.entity"
            :property="item.propName"
            :rootEntity="rootEntity"
            :boldLabels="true"
            :readOnly="isReadOnly(item)"
            @onAutoCompleteSelected="fillAutocompleteData"
            @linkMethodCallback="emitLinkMethodCallback"
          >
          </cc-var-control>
        </el-form-item>
      </div>
      <div v-if="!singleColumn" class="right">
        <el-form-item
          v-for="(item, itemIdx) in getColumnItems(1)"
          :label="getLabelText(item.meta)"
          :title="getLabelTitle(item.meta)"
          :prop="getValidationPropName(item.meta, entityIdx)"
          :key="itemIdx"
          :class="getLabelClass(item.meta)"
        >
        <!--:style="item.meta.childIdent == true ? 'margin-left:4em' : ''"-->
          <cc-var-control
            :metadata="item.meta"
            :entity="item.entity"
            :property="item.propName"
            :rootEntity="rootEntity"
            :boldLabels="true"
            :readOnly="isReadOnly(item)"
            @onAutoCompleteSelected="fillAutocompleteData"
            @linkMethodCallback="emitLinkMethodCallback"
          >
          </cc-var-control>

          <div v-if="hasResetButton(item)" class="lookup-object-buttons">
            <a href="javascript:void(0);" v-on:click="resetObject(item)">{{
              getResetButtonText
            }}</a>
            <a
              v-if="hasEditButton(item)"
              href="javascript:void(0);"
              v-on:click="onEditButton()"
              class="edit-button"
              >{{ getEditButtonText }}</a
            >
          </div>
        </el-form-item>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

/* eslint-disable */
export default {
  name: 'CcVarGroup',

  props: {
    metadata: null,
    entity: {},
    entityIdx: undefined,
    rootEntity: {},
    showTitle: false,
    readOnly: false,
    addLookupMandatoryActions: false,
    singleColumn: false,    
  },

  mounted() {
  },

  computed: {
    ...mapGetters(['globalMetadata']),

    hasReadonlyCondition() {
      return (
        this.metadata &&
        this.metadata.readOnlyCondition &&
        this.metadata.readOnlyCondition.formula
      )
    },

    getResetButtonText() {
      return this.hasReadonlyCondition
        ? this.metadata.readOnlyCondition.resetButtonText
        : ''
    },

    getEditButtonText() {
      return this.hasReadonlyCondition
        ? this.metadata.readOnlyCondition.editButtonText
        : ''
    },
  },

  methods: {
    getColumnItems(colIdx) {
      var result = []
      if (!this.metadata || !this.metadata.columns) {
        return result
      }

      for (var i = 0; i < this.metadata.columns[colIdx].length; i++) {
        var meta = this.metadata.columns[colIdx][i]

        // hack for items of inquiry and order
        var path = meta.id.startsWith('item.')
          ? meta.id.substring('item.'.length)
          : meta.id

        var hierObj = this.findHierObjectAndPropertyName(this.entity, path)

        if (this.shouldShow(meta, hierObj.entity)) {
          result.push({
            meta: meta,
            entity: hierObj.entity,
            propName: hierObj.propName,
          })
        } else { 
          //console.warn('Item not shown: ')
          //console.warn(hierObj)
        }
      }

      return result
    },

    shouldShow(col, item) {
      if (!item)
        return false

      if (item && col.showIfFormula && col.showIfFormula.length > 0) {
        try {
          var result = eval(col.showIfFormula)
          return result
        } catch (e) {
          console.error('ERROR: evaluateFormula: ' + e.message)
        }
      }

      return true
    },

    getTitle() {
      return this.metadata ? this.metadata.title : '[NO TITLE]'
    },

    getLabelText(meta) {
      const maxLen = 22
      const overflowSuffix = '...'

      var text = this.translate(meta.text)
      if (text && text.length > maxLen) {
        text = text.substring(0, maxLen - overflowSuffix.length)
        text += overflowSuffix
      }

      return text
    },

    getLabelTitle(meta) {
      return meta.hint 
        ? this.translate(meta.hint)
        : this.translate(meta.text)
    },

    getLabelClass(meta) {
      if (meta.type == 'header') {
        return 'varcontrol-header'
      }

      return ''
    },

    fillAutocompleteData(entity, property, lookupEntityType, data) {
      const that = this
      this.timeout = setTimeout(() => {
        var url = `v1/lookup/${lookupEntityType}/${data.id}`
        if (this.addLookupMandatoryActions) {
          url += '?addMandatoryActions=true'
        }

        this.$http
          .get(url)
          .then((res) => {
            if ( lookupEntityType == 'Component' && this.$attrs.onOnExistingComponentSelected ) {
              this.$emit('onExistingComponentSelected', res.data)
            } else {
              Object.keys(res.data).forEach(function (key, index) {
                that.entity[key] = res.data[key]
              })
            }
          })
          .catch()
      }, 0)
    },

    emitLinkMethodCallback(method,args) {
      this.$emit('linkMethodCallback', method, args)
    },

    isReadOnly(item) {
      if (this.readOnly) {
        return true
      }

      const isReadonly = this.evalIsReadonlyFormula()
      const result = isReadonly && this.matchesReadonlyMask(item.meta.id)
      // console.log('-- matchesReadonlyMask ' + item.meta.id + ' ' + result)
      return result
    },

    evalIsReadonlyFormula() {
      if (!this.hasReadonlyCondition) {
        return false
      }

      try {
        const item = this.entity
        var result = eval(this.metadata.readOnlyCondition.formula)
        return result
      } catch (e) {
        console.error('ERROR: evaluate isReadOnly formula:')
        console.error(e.message)
      }

      return false
    },

    matchesReadonlyMask(metaId) {
      if (!this.hasReadonlyCondition) {
        return false
      }

      const mask = this.metadata.readOnlyCondition.readOnlyProperty + '.'
      const result = metaId.startsWith(mask)
      return result
    },

    hasResetButton(item) {
      const result =
        !this.readOnly &&
        this.hasReadonlyCondition &&
        this.evalIsReadonlyFormula() &&
        item.meta.id == this.metadata.readOnlyCondition.controlPropertyName

      return result
    },

    hasEditButton(item) {
      const result =
        !this.readOnly &&
        this.hasReadonlyCondition &&
        this.evalIsReadonlyFormula() &&
        item.meta.id == this.metadata.readOnlyCondition.controlPropertyName &&
        this.metadata.readOnlyCondition.editButtonFormula &&
        this.metadata.readOnlyCondition.editButtonText

      return result
    },

    resetObject(item) {
      const readOnlyProperty = this.metadata.readOnlyCondition.readOnlyProperty
      this.entity[readOnlyProperty] = {}
    },

    onEditButton() {
      const item = this.entity
      var path = eval(this.metadata.readOnlyCondition.editButtonFormula)
      this.navigateToLink(path)
    },
  },
}
</script>

<style scoped>
.lookup-object-buttons {
  display: flex;
}

.edit-button {
  margin-left: 20px;
}
</style>
